export function useCatalogFormatter() {

  const { locale } = useI18n();
  const { currency } = useCurrency(locale.value);

  function getMinimumPrice(item) {
    let price = null;
    const services = item.services.filter(service => ['demo', 'putinhuilo'].includes(service.type) === false);
    for (const key in services) {
      const value = services[key];
      if (value.fixedPrice) {
        price = value.fixedPrice[`price_${currency.value}`];
      } else {
        if (value.type === 'individual') {
          price = value.price[currency.value];
        }
      }
    }
    if (price === null) {
      price = services.reduce((previous, current) => {
        return current.price[currency.value] < previous.price[currency.value] ? current : previous;
      }).price[currency.value];
    }

    return price;
  }

  function getFixedPriceRange(service): number[] {
    if (service.fixedPrice) {
      if (service.fixedPrice[`price15_${currency.value}`] < service.fixedPrice[`price_${currency.value}`]) {
        return [Math.round(service.fixedPrice[`price15_${currency.value}`]), Math.round(service.fixedPrice[`price_${currency.value}`])];
      }
      return [Math.round(service.fixedPrice[`price_${currency.value}`])];
    } else {
      return [];
    }
  }

  function getOriginPriceRange(service): number[] {
    if (service.price15[currency.value]) {
      if (service.price15[currency.value] < service.price[currency.value]) {
        return [Math.round(service.price15[currency.value]), Math.round(service.price[currency.value])];
      }
    }
    return [Math.round(service.price[currency.value])];
  }

  return {
    getMinimumPrice,
    getFixedPriceRange,
    getOriginPriceRange,
  }
}
